import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/administration": [3],
		"/administration/dodavatele": [4],
		"/administration/dodavatele/advance_order": [5],
		"/administration/export": [6],
		"/administration/naskladneni": [7],
		"/administration/obrazky": [8],
		"/administration/obsah": [9],
		"/administration/ostatni": [10],
		"/administration/ostatni/balikobot": [11],
		"/administration/ostatni/glssk": [13],
		"/administration/ostatni/gls": [12],
		"/administration/pouzite": [14],
		"/administration/zbozi": [15],
		"/auth/login": [16],
		"/blog": [~17],
		"/blog/[slug]": [18],
		"/brno": [19],
		"/brno/dynamometer-motorova-brzda-amerschlaeger": [20],
		"/brno/historie": [21],
		"/brno/servis-brno": [22],
		"/brno/tym": [23],
		"/cenik": [24],
		"/elektromobil_1": [25],
		"/elektromobil_2": [26],
		"/eshop": [27],
		"/eshop/cart/payment": [29],
		"/eshop/podminky": [30],
		"/eshop/zasielame-na-slovensko": [31],
		"/eshop/[slug]": [28],
		"/graf-vykonu-z-motorove-brzdy/[slug]": [32],
		"/kontakt": [33],
		"/kosik": [34],
		"/kosik/doprava-a-platba": [35],
		"/kosik/druha-platba": [36],
		"/kosik/dvojita-platba": [37],
		"/kosik/platba-poznamka": [38],
		"/kosik/platba-selhala": [39],
		"/kosik/podekovani": [40],
		"/kosik/rekapitulace": [41],
		"/login": [42],
		"/objednavka": [43],
		"/objednavka/[slug]": [44],
		"/reklamace": [45],
		"/rezervace": [46],
		"/rezervace/servis": [47],
		"/rezervace/vymena": [48],
		"/scooter-tuning-jaknato": [49],
		"/scooter-tuning-jaknato/[slug]": [50],
		"/skutr-[slug]": [~51],
		"/skutr-[slug]/blog": [~52],
		"/skutr-[slug]/nahradni-dily": [~53],
		"/skutr-[slug]/nahradni-dily/originalni": [~55],
		"/skutr-[slug]/nahradni-dily/[slug]": [~54],
		"/skutr-[slug]/pouzite-dily": [~56],
		"/skutr-[slug]/pouzite-dily/[slug]": [~57],
		"/skutr-[slug]/rozkresy": [~58],
		"/skutr-[slug]/rozkresy/[slug]": [59],
		"/skutr-[slug]/technicke-udaje": [~60],
		"/skutr-[slug]/zvyseni-vykonu": [~61],
		"/skutry": [~62],
		"/skutry/jak-dobre-koupit-skutr": [65],
		"/skutry/[slug]": [~63],
		"/skutry/[slug]/[slug]": [~64],
		"/univerzalni-dily": [~66],
		"/univerzalni-dily/pneumatiky": [68],
		"/univerzalni-dily/[slug]": [~67],
		"/uzbozi/[slug]": [69],
		"/vyhledavani": [70],
		"/vyhledavani/[slug]": [71],
		"/vykup": [72],
		"/vyrobci": [73],
		"/vyrobci/[slug]": [74],
		"/vyrobci/[slug]/[slug]": [75],
		"/zasielame-na-slovensko": [76],
		"/zbozi/[slug]": [77]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';